.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  font-size: 0.8rem;
  /* border-bottom: 1px solid #e6e6e6; */
  background-color: black;
}

.navbar > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
  text-align: center;
  align-items: center;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 30px;
  text-decoration: none;
  font: 100%/1.5 "Roboto", Verdana, sans-serif;
}

li a:hover {
  /* background-color: grey; */
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .navbar a {
    float: none;
    width: 20%;
    display: none;
  }
}
