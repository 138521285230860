* {
  margin: 0;
  padding: 0;
  /* border: 1px dotted red; */
}

html {
  -webkit-font-smoothing: antialiased;
  font: 100%/1.5 "Roboto", Verdana, sans-serif;
}

body {
  background-color: black;
  font-family: Helvetica;
}

.container {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.name {
  color: white;
  font: 600%/1.5 "Roboto", Verdana, sans-serif;
}

.jobDescription {
  color: white;
  font: 170%/1.5 "Roboto", Verdana, sans-serif;
}

.email {
  color: white;
  font: 120%/1.5 "Roboto", Verdana, sans-serif;
}

@media only screen and (max-width: 600px) {
  .name {
    font: 300% "Roboto", Verdana, sans-serif;
  }

  .jobDescription {
    font: 110% "Roboto", Verdana, sans-serif;
  }

  .email {
    font: 80% "Roboto", Verdana, sans-serif;
  }

  .removeOnSmallScreen {
    display: none;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

/* .ccc {
  color: white;
  padding: 15px;
  /* position: absolute; */
/* top: 51%; */
/* left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
} */

/* 
.ccc a {
  color: white;
  text-decoration: none;
  font: 100%/1.5 "Roboto", Verdana, sans-serif;
} */
/* 
.textFillName {
  background-color: white;
  position: relative;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.textFillName::before {
  content: attr(data-title);
  background: linear-gradient(#f107ba, #155af0);
  position: absolute;
  top: 0;
  white-space: nowrap;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0%;
  transition: all 0.9s ease-in-out;
  vertical-align: middle;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.textFillName:hover::before {
  width: 100%;
} */
/* 
.textfillCompany {
  background-color: white;
  position: relative;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.textfillCompany::before {
  content: attr(data-title-company);
  background: linear-gradient(#eea63b, white);
  position: absolute;
  top: -3.2px;
  white-space: nowrap;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0%;
  transition: all 0.9s ease-in-out;
  vertical-align: middle;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.textfillCompany:hover::before {
  width: 100%;
} */
