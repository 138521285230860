.links > h1 {
  font: 100%/1.5 "Roboto", Verdana, sans-serif;
}
.linksList li {
  float: none !important;
  text-align: center;
  padding: 2px 5px !important;
}
.linksList li a {
  padding: 0 !important;
}
