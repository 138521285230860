header {
  margin-bottom: 1.5rem;
}
h1 {
  margin-bottom: 0.5rem;
  color: #333;
}
h2 {
  color: #333;
}
strong {
  color: #333;
}
time {
  color: #888;
  text-decoration: italic;
}
pre {
  white-space: pre-wrap;
}
hr {
  margin-top: 2rem;
}
#fn {
  font-size: 85%;
}
/* a {
  color: #ff3c3c;
  text-decoration: none;
  outline: 0;
}
a:hover {
  text-decoration: underline;
} */
::selection {
  background-color: babyblue;
  text-shadow: none;
  color: black;
}

.blog {
  background-color: #fffaf7;
  line-height: 1.5;
  margin: 0 auto;
  /* margin-top: 50px; */
  color: #555;
  padding: 2ch;
  margin: auto;
  font-family: Liberation Sans, Arial, sans-serif;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  position: absolute;
}

.blogContent {
  width: 70ch;
  position: relative;
  /* height: 100%; */
  margin-top: 40px;
}

article {
  padding: 20px;
}

li {
  float: left;
  text-decoration: none;
}

li a {
  display: block;
  color: white;
  text-align: center;
  /* padding: 30px; */
  text-decoration: none;
  font: 100%/1.5 "Roboto", Verdana, sans-serif;
}

.blogNav {
  background-color: #fffaf7 !important;
  width: 100%;
  position: relative;
  top: 50%;
  z-index: 1;
  list-style-type: none;
}

.blogNav li {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .blog {
    margin-left: 50px;
    /* display: none; */
  }
}
